@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes fromTop {
  0% {
    transform: translateY(-30%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes fromBottom {
  0% {
    transform: translateY(30%);
  }
  100% {
    transform: translateY(0%);
  }
}