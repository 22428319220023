/* define css variable */
:root {
    --dark-accent: rgb(33, 87, 6);
    --light-accent: rgb(153, 187, 255);
}

.text {
    transition: color 0.5s;
}

#body {
    min-width: 100vw;
    min-height: 100vh;

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

@supports (-webkit-touch-callout: none) {
    #body {
        min-height: -webkit-fill-available;
    }
}

/* ! --------- ! */
/* * DARK MODE * */
/* ! --------- ! */

.webp #body.light {
    background: url('../img/background2webp.webp'); 
    background-size: cover;
}

.no-webp #body.light, #body.light {
    background: url('../img/background2.png'); 
    background-size: cover;
}

#body.light {
    color: #171710;
    fill: #171710;
}

#body.light #background-color-fader {
    background-color: #fff;
    opacity: 0.3;
}

#body.light #period {
    background-color: var(--light-accent);
}

.webp #body.dark {
    background: url('../img/background1webp.webp'); 
    background-size: cover;
}

.no-webp #body.dark, #body.dark {
    background: url('../img/background.png'); 
    background-size: cover;
}

#body.dark #period {
    background-color: var(--dark-accent);
}

#body.dark {
    color: #e8e8ef;
    fill: #e8e8ef;
}

#body.dark #background-color-fader {
    background-color: #000;
    opacity: 0.7;
}

#body.dark #see-more-divider {
    background-color: #e8e8ef;
}

/* ! ----------- ! */
/* ! MAIN STYLES ! */
/* ! ----------- ! */

a {
    text-decoration: none;
}

#see-more-container {
    /* Disabled in mobile */
    display: none;
    z-index: 2;
    margin-right: 8%;
}

#see-more-title {
    font-size: 30px;
    font-weight: 500;
}

#see-more-divider {
    margin-top: 5px;
    margin-left: 10%;
    width: 80%;
    height: 1px;
    background-color: #171710;
}

#see-more-title {
    opacity: 0;
    animation: 0.75s ease-out 0s 1 fadeIn,
        0.75s ease-out 0s 1 fromTop;
    animation-fill-mode: forwards;
    animation-delay: 1s;
}


#see-more-divider, .project {
    opacity: 0;
    animation: 0.75s ease-out 0s 1 fadeIn,
        0.75s ease-out 0s 1 fromBottom;
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
}

#see-more-project-list {
    padding-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#see-more-project-list .project {
    font-weight: 100;
    font-size: 20px;

    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

#background-color-fader {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;

    transition: background-color 0.5s;
}

#light-mode-controls {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 30px;
    gap: 15px;

    z-index: 2;

    display: flex;
    align-items: center;
}

.mode-select {
    width: 36px;
    height: 36px;
    border-radius: 100%;
}

#dark-mode-select {
    box-sizing: border-box;
    background-color: var(--dark-accent);
}

#dark-mode-select.selected {
    border: 3px solid #e8e8ef;
}

#light-mode-select {
    box-sizing: border-box;
    background-color: #acf;
}

#light-mode-select.selected {
    border: 3px solid #171710;
}

#intro-container {
    display: flex;
    flex-direction: column;
    
    margin-left: 8%;

    filter: opacity(1);
    transform: translateX(0px);
    transition: filter 0.5s ease 0.5s, transform 0.5s ease 0.5s;
}

#intro-container:not(.intro) {
    pointer-events: none;
    filter: opacity(0);
    transform: translateX(-10px);
    transition-delay: 0s;
}

#projects-container {
    display: flex;
    flex-direction: column;
    
    margin-left: 8%;

    background-color: rgba(255, 255, 255, 0.4);
    padding: 20px;
    border-radius: 5px;

    max-height: 90vh;
    overflow-y: scroll;

    filter: opacity(1);
    transform: translateX(0px);
    transition: filter 0.5s ease 0.5s, transform 0.5s ease 0.5s;
}

#projects-container:not(.projects) {
    pointer-events: none;
    filter: opacity(0);
    transform: translateX(-10px);
    transition-delay: 0s;
}

#body.dark #projects-container {
    background-color: rgba(0, 0, 0, 0.4);
}

#project-section-details {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 8px;
}

#project-section-title {
    font-size: 30px;
    font-weight: 900;
}

#project-section-subtitle {
    font-size: 14px;
    font-weight: 100;
    max-width: 80%;
}

#individual-project-container {
    width: 100%;

    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}

.ip-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 20px;

    min-width: 50%;
    flex: 1;
    padding: 10px;
    box-sizing: border-box;
}

.ip-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
}

.ip-title {
    font-size: 30px;
    font-weight: 900;
}

.ip-created {
    font-weight: 100;
    font-size: 12px;
    margin-bottom: 8px;
    margin-top: -3px
}

.ip-description {
    font-size: 14px;
    font-weight: 100;
}

.light .ip-description {
    font-weight: 400;
}

.container {
    z-index: 2;
    position: absolute;
    left: 0;
}

.gap-top {
    margin-top: 10px;
}

#name {
    font-size: 60px;
    line-height: 60px;

    font-weight: 500;

    animation: 0.75s ease-out 0s 1 fadeIn,
        0.75s ease-out 0s 1 fromTop;
}

#name #period {
    display: inline-block;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 0.2rem;
    margin-left: 2px;
}

#pronunciation {
    font-size: 20px;
    line-height: 20px;
    font-weight: 300;
    margin-bottom: 30px;
    opacity: 0;

    animation: 0.75s ease-out 0.3s 1 fadeIn,
        0.75s ease-out 0.3s 1 fromBottom;
    animation-fill-mode: forwards;
}

#slogan {
    font-size: 20px;
    opacity: 0;

    display: flex;

    animation: 0.75s ease-out 0.75s 1 fadeIn,
        0.75s ease-out 0.75s 1 fromBottom;
    animation-fill-mode: forwards;
}

#socials {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.swap-projects {
    font-size: 20px;
    font-weight: 100;

    font-family: 'Courier New', Courier, monospace;

    color: var(--dark-accent);
    filter: brightness(2) grayscale(0.3);
}

#intro-container .swap-projects {
    margin-top: 20px;
}

/* ! MORE LINKS */
#more-links-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 20px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    overflow-x: auto;
    background-color: #f0f0f0;
    color: #050505;
    width: 350px;

    #body.dark & {
        background-color: #333;
        color: #e8e8ef;
        box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
    }
}

.more-links-link {
    display: inline-block;
    transition: background-color 0.5s, color 0.5s;

    &:hover {
        cursor: pointer;

        & span {
            display: inline-block;
            animation: bounce 0.5s;
        }
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
    40% { transform: translateY(-4px); }
    60% { transform: translateY(-2px); }
}

@for $i from 1 through 20 {
    .more-links-link span:nth-child(#{$i}) {
        animation-delay: 0.02s * $i;
    }
}
/* ! MORE LINKS */

.light .swap-projects {
    color: var(--light-accent);
    filter: brightness(0.3) grayscale(0.3);
    font-weight: 400;
}

.swap-projects:hover {
    cursor: pointer;
    filter: brightness(3) grayscale(1);
}

.light .swap-projects:hover {
    cursor: pointer;
    filter: brightness(0.4) grayscale(0.1);
}


/* ! SVG SECTION */

#socials svg {
    width: 36px;
    height: 36px;
}

#socials a {
    opacity: 0;

    animation: 0.75s ease-out 0s 1 fadeIn,
        0.75s ease-out 0s 1 fromBottom;
    animation-fill-mode: forwards;
}

#socials a:nth-child(1) {
    animation-delay: 1s
}

#socials a:nth-child(2) {
    animation-delay: 1.1s
}

#socials a:nth-child(3) {
    animation-delay: 1.2s
}

#socials a:nth-child(4) {
    animation-delay: 1.3s
}

@media only screen and (min-width: 800px) {
    #name {
        font-size: 100px;
        line-height: 100px;
    }

    #slogan {
        font-size: 30px;
    }

    #see-more-container {
        display: block;
    }
}

// ! SECRET STYLES ! //

#secret-text {
    font-size: 64px;
    font-weight: bold;
    color: black;
    z-index: 5;

    #body.dark & {
        color: white;
    }

    opacity: 0;
    position: absolute;
    bottom: -100%;
    width: 100%;
    text-align: center;
    transform: rotate(0deg);
    transition: opacity 0.5s, transform 1s, bottom 1s;

    &.reveal {
        animation: reveal-toss 2s ease-out forwards;
    }

    @keyframes reveal-toss {
        0% {
            opacity: 0;
            bottom: -50%;
            transform: rotate(0deg);
        }
        30% {
            opacity: 1;
            bottom: 50%;
            transform: rotate(360deg) scale(1);
        }
        100% {
            opacity: 0;
            bottom: 50%;
            transform: rotate(720deg) scale(10);
        }
    }
}

#secret2-label {
    margin-top: 8px;
}

#secret3-label {
    margin-left: 44px;
}

.more-links-link.stage1, .more-links.stage1 {
    color: rgba(200, 25, 25);

    #body.dark & {
        color: rgba(255, 128, 128);
    }
}

.more-links-link.stage2, .more-links.stage2 {
    color: rgba(200, 25, 200);

    #body.dark & {
        color: rgba(255, 128, 255);
    }
}

.more-links-link.stage3, .more-links.stage3 {
    color: rgba(25, 25, 200);

    #body.dark & {
        color: rgba(128, 128, 255);
    }
}

.entity-dialogue {
    margin-bottom: 12px;
    font-weight: bold;
}

.narrative-dialogue {
    margin-bottom: 12px;

    #body:not(.dark) & {
        font-style: italic;
    }
    #body.dark & {
        font-style: italic;
        filter: brightness(0.85);
    }
}

.user-dialogue-option {
    &:before {
        content: '> ';
    }
    &:hover {
        cursor: pointer;
        animation: oscillate 0.5s infinite alternate;
        
        #body:not(.dark) & {
            font-weight: bold;
        }
        #body.dark & {
            filter: brightness(0.85);
        }
    }
}

@keyframes oscillate {
    0% {
        transform: translateX(0px);
    }
    100% {
        transform: translateX(5px);
    }
}

.emphasis {
    display: inline-block;
}

.shake {
    animation: shake 0.5s ease-in infinite !important;
}

.shakeRotate {
    animation: shakeRotate 0.5s ease-in infinite !important;
}

@keyframes shake {
    0%, 100% { transform: translate(0, 0); }
    10%, 30%, 50%, 70%, 90% { transform: translate(0px, -2px); }
    20%, 40%, 60%, 80% { transform: translate(0px, 2px); }
}

@keyframes shakeRotate {
    0%, 100% { transform: rotate(0deg); }
    10%, 30%, 50%, 70%, 90% { transform: rotate(-1deg); }
    20%, 40%, 60%, 80% { transform: rotate(1deg); }
}

@keyframes fallOffScreen {
    0% {
        opacity: 1;
        transform: translateY(0) rotate(0deg);
    }
    100% {
        opacity: 0;
        transform: translateY(100vh) rotate(360deg);
    }
}

.falling {
    animation: fallOffScreen 2s ease-out forwards !important;
}

.slowAnimation {
    animation-duration: 10s !important;
}

@keyframes flyAround {
    0% {
        transform: translate(0, 0);
    }
    25% {
        transform: translate(0, 25vh);
    }
    50% {
        transform: translate(30vw, 20vh);
    }
    75% {
        transform: translate(40vw, -20vh);
    }
    100% {
        transform: translate(0, 0);
    }
}

.fly-around {
    animation: flyAround 4s ease-in-out infinite;
}

.gigantic {
    font-size: 32px;
    font-family: 'Comic Sans MS', cursive, sans-serif;
}

input[type="text"], input[type="email"], input[type="password"] {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s, box-shadow 0.3s;

    &:focus {
        border-color: var(--light-accent);
        box-shadow: 0 0 5px rgba(153, 187, 255, 0.5);
        outline: none;
    }

    &::placeholder {
        color: #999;
    }
}

#body.dark input[type="text"], #body.dark input[type="email"], #body.dark input[type="password"] {
    background-color: #333;
    color: #e8e8ef;
    border-color: #555;

    &:focus {
        border-color: var(--dark-accent);
        box-shadow: 0 0 5px rgba(33, 87, 6, 0.5);
    }

    &::placeholder {
        color: #bbb;
    }
}

.hidden {
    display: none;
}